<template>
  <div class="mainContainer container" style="">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Change Password</h5>
    </div>
    <hr>


    <div class="row" v-if="pageStatus !== 'changeSuccess'">
      <div class="col-md-12">

        <div class="card-container">
          <form @submit.prevent="handlePasswordChange" >

            <div class="form-group" >
              <label for="oldpassword">Old Password</label>
              <input name="oldpassword" type="password" class="form-control" autocomplete="new-password"  v-model="oldPassword"/>
              <ul class="error-feedback" v-if="oldPasswordErrors.length > 0" v-html="oldPasswordErrors"></ul>
            </div>

            <div class="form-group">
              <label for="password">New Password</label>
              <input name="password" type="password" class="form-control" autocomplete="new-password" v-model="password" />
              <ul class="error-feedback" v-if="passwordErrors.length > 0" v-html="passwordErrors"></ul>
            </div>

            <div class="form-group">
              <label for="passwordConfirmation">Confirm New Password </label>
              <input id="passwordConfirmation" name="passwordConfirmation" type="password"  class="form-control" autocomplete="new-password" v-model="passwordConfirmation"/>
              <ul class="error-feedback" v-if="passwordConfirmationErrors.length > 0" v-html="passwordConfirmationErrors"></ul>
            </div>

              <div class="form-group" >
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Submit</span>
                </button>
              </div>


          </form>

          <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
          >
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="pageStatus === 'changeSuccess'">
      <div class="col-md-12 msg">
        Your password has been successfully updated. Continue to <span class="alink" @click="this.$root.gotoRoute({ path: '/login' })">Log In</span>.
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: "PasswordChange",
  components: {

  },
  data() {

    return {
      successful: false,
      loading: false,
      message: "",
      pageStatus:'',
      oldPassword:null,
      password:null,
      passwordConfirmation:null,
      passwordValidationErrors:'',

      oldPasswordErrors:'',
      passwordErrors:'',
      passwordConfirmationErrors:'',
      hasValidationError: false,

    };
  },
  computed: {

  },
  mounted() {
    document.title = "Change Password";
  },
  methods: {
    handlePasswordChange() {

      this.$root.playClickSound();

      this.hasValidationError = false;

      this.validateOldPassword();
      this.validatePassword();
      this.validatePasswordConfirmation();

        if(this.hasValidationError){
          this.successful = false;
          this.loading = false;
          return;
        }

      this.message = "";
      this.successful = false;
      this.loading = true;

        this.axios
            .post(this.$hostApi,{task:'changePass', oldPassword:this.oldPassword, password:this.password, passwordConfirmation:this.passwordConfirmation},{
              headers:{
                'token':localStorage.getItem('user')
              }
            })
            .then(response =>{
              //console.log(response);
              if(response.data === 'ok'){
                this.pageStatus = 'changeSuccess';
                this.successful = false;
                this.loading = false;
                localStorage.removeItem('user');
              }else if(response.data === 'invalid'){
                this.successful = false;
                this.loading = false;
                this.message = 'The old password is incorrect!'
              }
            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            })


    },

    showRegForm(){

    },

    validateOldPassword(){
      this.oldPasswordErrors = this.$root.validateLength(this.oldPassword,6,20);
      if(this.oldPasswordErrors.length > 0){
        this.hasValidationError = true;
      }
    },


    validatePassword(){
      this.passwordErrors = this.$root.validateLength(this.password,6,20);
      if(this.passwordErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePasswordConfirmation(){
      this.passwordConfirmationErrors = this.$root.validateLength(this.passwordConfirmation,6,20);
      if(this.passwordConfirmationErrors.length > 0){
        this.hasValidationError = true;
      }

      if(this.passwordConfirmation !== this.password){
        this.passwordConfirmationErrors += "<li>passwords don't match</li>";
        this.hasValidationError = true;
      }
    },
  },
};
</script>

<style scoped>
#logoDiv{
  margin:0 auto;
  padding:10px 20px;
  text-align: center;
}


.error-feedback{
  font-size:10px;
  color:red;

}

.error-feedback-div{
  margin-bottom:5px;
}

.form-group{
  margin:20px;
}


</style>
